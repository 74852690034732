<template>
  <div class="tbt-buy-page">
    <v-row justify="center" class="my-15">
      <v-col cols="11" sm="9" md="6">
        <v-card light class="exchange-card card-wrap d-flex flex-column justify-center align-center">
          <img src="@/assets/img/icon-tbt.png" width="60px" class="mb-5">
          <h2 class="primary--text mb-10">{{ $t('buyTBTTitle') }}</h2>
          <addressBlock></addressBlock>

          <div>{{ $t('enterUSDTAmount') }}</div>
          <v-form ref="form" style="width: 100%;" lazy-validation>
            <v-text-field
              class="my-1"
              v-model="amount"
              outlined
              persistent-hint
              :hint="`Balance: ${balance} USDT`"
              color="primary"
              placeholder="0"
              :label="`${$t('enterExchangeUSDTAmount')}`"
              :rules="[...TokenAmountRules, ...balanceRules]"
            >
              <template v-slot:append>
                <div @click="amount=balance">Max</div>
              </template>
            </v-text-field>
          </v-form>

          <div class="primary--text mb-10">{{ $t('swapToTbt', {amount: amount ? (amount*usdtRate).toFixed(4) : 0, tbtValue: usdtRate.toFixed(4)}) }}</div>

          <btn v-if="allowance===0 || allowance<balance" class="mb-5" :buttonText="'sellApprove'" color="#E53935" isDark :isCenter="true" :width="270" @clickBtn="approve()"></btn>
          <btn v-else class="mb-5" :buttonText="'buy'" color="primary" :isCenter="true" :width="270" @clickBtn="clickBuyBtn()"></btn>
          <div class="can-click" @click="$router.push({name: 'Home'})">{{ $t('backToIndex') }}</div>
        </v-card>
      </v-col>
    </v-row>
    <loading :loadingShow="loadingShow" :content="'waitApprove'"></loading>
  </div>
</template>
<script>
import base from '@/mixin/base.js'
import btn from '@/components/btn.vue'
import addressBlock from '@/components/addressBlock.vue'
import loading from '@/components/loading.vue'
export default {
  name: "TBT",
  mixins: [base],
  data (){
    return {
      amount: null,
      balance: 0,
      allowance: 0,
      loadingShow: false,
      timer: null,
      balanceRules: [
        (v) => v <= this.balance || 'Under Balance'
      ],
      usdtRate: 0,
    }
  },
  components:{
    btn,
    addressBlock,
    loading,
  },
  methods:{
    async clickBuyBtn(){
      if (this.$refs.form.validate()){
        this.buyTBT()
      }
    },
    async buyTBT(){
      if (this.$store.state.chainId){
        let result = await this.$defi.swap('toUsdt', this.amount)
        // console.log('result', result)
        if (result.txHash){
          await this.getBalance()
          this.$toasted.show(this.$t('txSend'))
          this.$refs.form.reset()
        }else if (result.code === 4001){
          this.$toasted.error(this.$t('userRefuse'))
        }
      }else{
        this.$toasted.error(this.$t('changeMainnet'))
      }
    },
    async getBalance(){
      let total = await this.$usdt.getBalance(this.$store.state.account)
      this.balance = total
    },
    async getAllowance(){
      let total = await this.$usdt.getAllowance(this.$store.state.account)
      this.allowance = total
    },
    async approve(){
      if (this.$store.state.chainId){
        let result = await this.$usdt.approve()
        // console.log('result', result)
        if (result.txHash){
          this.loadingShow = true
          this.timer = window.setInterval(async () => {
            await this.getAllowance()
            if (this.allowance >= this.balance) {
              window.clearInterval(this.timer)
              this.loadingShow = false
            }
          }, 1000)
        }else if (result.code === 4001){
          this.$toasted.error(this.$t('userRefuse'))
        }
      }else{
        this.$toasted.error(this.$t('changeMainnet'))
      }
    },
  },
  async mounted(){
    // tbt contract
    this.usdtRate = await this.$defi.getRate('usdt')

    await this.getBalance()
    await this.getAllowance()
  }
}
</script>

<style lang="scss" scoped>
.tbt-buy-page{
  
}
</style>